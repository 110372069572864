import App from 'next/app';

export default class MyApp extends App {
   static async getInitialProps({ Component, router, ctx }) {
      let pageProps = {}
      if (Component.getInitialProps) {
         pageProps = await Component.getInitialProps(ctx)
      }
      return { pageProps }
   }

   /*constructor(props){
      super(props)
      this.state = {
         showInstallMessage: false
      }
   }

   componentDidMount = ()=>{
      const isIos = () => {
         const userAgent = window.navigator.userAgent.toLowerCase();
         return /iphone|ipad|ipod/.test( userAgent );
      }

      const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

      if(isIos() && !isInStandaloneMode()) {
         this.setState({ showInstallMessage: true });
      }
   }*/

   render () {
      const { Component, pageProps } = this.props
      return (
         <>
            {/*this.state.showInstallMessage && <div></div>*/}
            <Component {...pageProps} />
         </>
      )
   }
}
